<template>
  <v-app :class="siteMode === 'UH' ? 'signup-app-uh' : 'signup-app'">
    <v-container class="signup-container">
      <v-row class="text-center">
        <v-col cols="12">
          <v-img
            :src="siteMode === 'UH' ? logoUH : logoVP"
            class="mt-3"
            contain
            height="150"
          />
        </v-col>

        <v-col class="mb-4">
          <h1
            :class="
              siteMode === 'UH'
                ? 'display-2 black--text font-weight-bold'
                : 'display-2 white--text font-weight-bold'
            "
          >
            {{
              siteMode === 'UH' ? 'University Hospitals' : 'Welcome to VPExam'
            }}
          </h1>
        </v-col>
      </v-row>
      <v-card class="signup-card">
        <v-card-title
          >Please check your email and verify your account before logging
          in.</v-card-title
        >
        <v-card-text>
          If you did not receive an email, please complete the form below.
          <v-form class="mt-4" ref="form" v-model="valid" lazy-validation>
            <v-alert dense type="error" dismissible v-if="error">
              {{ error }}</v-alert
            >
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              rounded
              outlined
              dense
              required
            ></v-text-field>
            <v-btn :disabled="!valid" color="primary" @click="sendEmail"
              >Resend Verification</v-btn
            >
            <v-chip
              v-if="indicate.status"
              :color="indicate.color"
              outlined
              label
              class="ml-4"
            >
              {{ indicate.message }}
            </v-chip>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout'
import * as fb from '@/firebase'
import logoUH from '@/assets/logo.svg'
import logoVP from '@/assets/logo-white.png'

export default {
  name: 'ThankYou',
  data() {
    return {
      logoUH: logoUH,
      logoVP: logoVP,
      siteMode: process.env.VUE_APP_SITE,
      error: '',
      valid: true,
      indicate: { status: false, message: '', color: 'success' },
      nameRules: [v => !!v || 'Required'],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  watch: {
    indicate: function() {
      setTimeout(
        function() {
          this.indicate.status = false
        }.bind(this),
        10000
      )
    }
  },
  created() {
    this.$emit(`update:layout`, PublicLayout)
  },
  methods: {
    sendEmail() {
      this.loading = true
      if (this.$refs.form.validate()) {
        const resendVerification = fb.functions.httpsCallable(
          'resendVerification'
        )

        try {
          resendVerification({
            email: this.email
          })

          this.loading = false
          this.indicate.status = true
          this.indicate.message = 'Email Sent'
          this.indicate.color = 'success'
        } catch (error) {
          this.error = error.message
          this.loading = false
          this.indicate.status = true
          this.indicate.message = 'Error has occurred'
          this.indicate.color = 'error'
        }
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
#form {
  margin-top: 0;
}

.container {
  max-width: 737px !important;
}

.signup-container {
  max-width: 737px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.signup-card {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 100px;
}
.v-btn {
  text-transform: capitalize;
}

@media (max-width: 768px) {
}
</style>
